import { IUserRewardDetails } from '../store/types';

export const getSlicedValue = (val: string, sliceLength = 6) => {
  return (
    val.slice(0, sliceLength) + '...' + val.slice(val.length - sliceLength)
  );
};

export const getTotalValue = (
  data: IUserRewardDetails[],
  user_address: string
) => {
  return data
    .filter(
      (f) => f.address.toLocaleLowerCase() === user_address.toLocaleLowerCase()
    )
    .reduce((a, b) => a + b.earnedBonus, 0);
};
