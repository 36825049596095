import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { IStakeHolder } from '../store/types';
import { getStakeHolders } from '../utils/contractMethods';

export default function useStakeHolders() {
  const { account, library, chainId } = useWeb3React();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<IStakeHolder[]>([]);

  const handleGetGcsPlans = useCallback(async () => {
    if (account && chainId) {
      try {
        setIsLoading(true);
        setData(await getStakeHolders(account, library?.provider, chainId));
      } catch (error: any) {
        console.log(error);
        setError(error?.message);
      } finally {
        setIsLoading(false);
      }
    }
  }, [account, library, chainId]);

  useEffect(() => {
    handleGetGcsPlans();
  }, [handleGetGcsPlans]);

  return { isLoading, error, data, refetch: handleGetGcsPlans };
}
