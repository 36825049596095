import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Header, Sidebar } from './components';

import { useEagerConnect } from './hooks/useEagerConnect';

import { Home, StakeHolders } from './pages';
import { WalletContext } from './store/context/WalletContext';

const App: React.FC = () => {
  const res = useEagerConnect();
  const { account } = useWeb3React();
  const { setOpenWallet } = useContext(WalletContext);

  useEffect(() => {
    if (res) {
      if (account) return setOpenWallet(false);
      setOpenWallet(true);
    }
  }, [res, account, setOpenWallet]);

  if (!account) return null;

  return (
    <div>
      <Header />
      <div className="app_container">
        <Sidebar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stake-holders" element={<StakeHolders />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
