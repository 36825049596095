import { ethers, utils } from "ethers";
import {
  BonusType,
  IAddGcsPlan,
  IGcsPlan,
  IUserRewardDetails,
  IUserStakedDetails,
} from "../store/types";
import { loadStakingContract } from "./contracts";
import { getValidReferralCount } from "./referralsMethod";

export const zeroAddress = "0x0000000000000000000000000000000000000000";

export const sleep = (ms = 2000) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatEther = (val) => Number(utils.formatEther(val.toString()));
export const parseEther = (val) => utils.parseEther(val.toString()).toString();

const getUserStatus = (id) => {
  if (id === "0") return "STARTED";
  return "FINISHED";
};

export const getGcsPlanData = async (address: string, provider: any, chainId: number) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const details = await stakingContract.getPlanDetails();

  const gcsPlansData: IGcsPlan[] = details.map((d) => {
    const parseStaked = formatEther(d.staked.toString());

    return {
      name: d.name.toString(),
      apr: Number(d.apr.toString()),
      plan_id: Number(d.planid.toString()),
      stakeaddress: d.stakeaddress,
      day: Number(d.day.toString()),
      directbonus: Number(d.directbonus.toString()),
      gcexaddress: d.gcexaddress.toString(),
      total_staked: Number(parseStaked),
      withdrawfee: Number(d.withdrawfee.toString()),
      rewardaprbonus: Number(d.rewardaprbonus.toString()),
      level1DirectBonus: Number(d.level1DirectBonus.toString()),
      level2DirectBonus: Number(d.level2DirectBonus.toString()),
      validcount: Number(d.validcount.toString()),
      xamount: formatEther(d.amount[0].toString()),
      yamount: formatEther(d.amount[1].toString()),
      zamount: formatEther(d.amount[2].toString()),
    };
  });

  const data = ["Bronze", "Silver", "Gold"];

  const updatdPlan = gcsPlansData.concat(gcsPlansData[0]).map((f, i) => ({ ...f, name: data[i] }));
  return updatdPlan;
};

export const addGcsPlan = async (
  address: string,
  provider: any,
  chainId: number,
  planData: IAddGcsPlan
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  console.log(stakingContract);
  const {
    name,
    plan_id,
    apr,
    day,
    gcexaddress,
    withdrawfee,
    directbonus,
    rewardaprbonus,
    level1DirectBonus,
    level2DirectBonus,
    xamount,
    yamount,
    zamount,
    validcount,
  } = planData;

  const formattedX = parseEther(String(xamount));
  const formattedY = parseEther(String(yamount));
  const formattedZ = parseEther(String(zamount));

  const tx = await stakingContract.addGCSPlan(
    plan_id,
    name,
    apr,
    day,
    gcexaddress,
    withdrawfee,
    rewardaprbonus,
    directbonus,
    level1DirectBonus,
    level2DirectBonus,
    [formattedX, formattedY, formattedZ],
    validcount
  );
  await tx.wait();
};

export const updateGcsPlan = async (
  address: string,
  provider: any,
  chainId: number,
  planData: IAddGcsPlan
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  const {
    name,
    plan_id,
    apr,
    gcexaddress,
    withdrawfee,
    directbonus,
    rewardaprbonus,
    level1DirectBonus,
    level2DirectBonus,
    xamount,
    yamount,
    zamount,
    validcount,
  } = planData;

  const formattedX = parseEther(String(xamount));
  const formattedY = parseEther(String(yamount));
  const formattedZ = parseEther(String(zamount));

  const tx = await stakingContract.updateGCSPlan(
    name,
    plan_id,
    apr,
    gcexaddress,
    withdrawfee,
    rewardaprbonus,
    directbonus,
    level1DirectBonus,
    level2DirectBonus,
    [formattedX, formattedY, formattedZ],
    validcount
  );
  await tx.wait();
};

export const getUserRewardDetails = async (stakingContract: ethers.Contract, address: string) => {
  const data = await stakingContract.getRewardAmount(address);

  const res: IUserRewardDetails[] = data.map((d) => {
    return {
      address: d._address.toLowerCase(),
      earnedBonus: formatEther(d.amount.toString()),
      type: d[0].toString() === "0" ? BonusType.DIRECT : BonusType.REFERRAL_APR,
    };
  });

  return res;
};

export const getUserStakingDetails = async (stakingContract: ethers.Contract, address: string) => {
  const details = await stakingContract.getUserDetails(address);
  const referrer = await stakingContract.referrer(address);
  const referrerDetails = await stakingContract.getReferrerDetail(address);
  const level = await stakingContract.getLevel(address);
  const validReferralsCount = await getValidReferralCount(
    stakingContract,
    address,
    Number(level.toString())
  );
  const userRewardDetails = await getUserRewardDetails(stakingContract, address);

  const userStakedDetails: IUserStakedDetails[] = await Promise.all(
    details.map(async (d, index) => {
      const staked_amount = formatEther(d.amount.toString());
      const rewards = await stakingContract.calculateReward(index, address);

      return {
        staked_amount: Number(staked_amount),
        apr: Number(d.apr.toString()),
        start_time: Number(d.starttime.toString()) * 1000,
        end_time: Number(d.endtime.toString()) * 1000,
        days: Number(d.day.toString()),
        plan: Number(d.plan.toString()),
        old_user: d.stake === true ? true : false,
        status: getUserStatus(d.status.toString()),
        index,
        totalRewardsWithInitialAmount: Number(formatEther(rewards[0].toString())),
        totalRewards: Number(formatEther(rewards[1].toString())),
        dailyReward: Number(formatEther(rewards[2].toString())),
      };
    })
  );

  const ongoingStakingDetails = userStakedDetails.filter((f) => f.status === "STARTED");
  const finishedStakingDetails = userStakedDetails.filter((f) => f.status === "FINISHED");

  const totalRewards = await stakingContract.totalRewards(address);

  return {
    level: Number(level.toString()),
    referral_id: Number(referrerDetails.id.toString()),
    referred_users: referrerDetails.users,
    ongoingStakingDetails,
    finishedStakingDetails,
    referrer: referrer.referrer.toString(),
    referrer_endtime: referrer.referrerendtime.toString(),
    validReferralsCount,
    user_address: address,
    total_staked: userStakedDetails.reduce((a, b) => a + b.staked_amount, 0),
    current_stake: ongoingStakingDetails.reduce((a, b) => a + b.staked_amount, 0),
    withdrawn: finishedStakingDetails.reduce((a, b) => a + b.staked_amount, 0),
    totalRewards: Number(formatEther(totalRewards.toString())),
    userRewardDetails,
  };
};

export const getStakeHolders = async (address: string, provider: any, chainId: number) => {
  let userAddress: string[] = [];
  const stakingContract = loadStakingContract(address, provider, chainId);

  const details = await stakingContract.getPlanDetails();

  details.forEach((d) => {
    d.stakeaddress.forEach((add) => userAddress.push(add));
  });

  const newUserAddress = [...new Set(userAddress)];

  const result = await Promise.all(
    newUserAddress.map(async (ref) => {
      return await getUserStakingDetails(stakingContract, ref);
    })
  );

  return result;
};

export const getUserReferralsList = async (
  address: string,
  provider: any,
  chainId: number,
  user_address: string
) => {
  let userAddress: string[] = [];
  const stakingContract = loadStakingContract(address, provider, chainId);
  const referrerDetails = await stakingContract.getReferrerDetail(user_address);
  userAddress = [...referrerDetails.users];
  return userAddress;
};
