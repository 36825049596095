import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { getUserReferralsList } from '../utils/contractMethods';

export default function useGetReferralsList(address: string) {
  const { account, chainId, library } = useWeb3React();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<string[]>([]);

  const handleGetData = useCallback(async () => {
    if (account && chainId && address) {
      try {
        setLoading(true);
        setData(
          await getUserReferralsList(
            account,
            library?.provider,
            chainId,
            address
          )
        );
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [account, library, chainId, address]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  return { loading, error, data };
}
