import React, { useState } from 'react';
import { useUpdateEffect } from '../hooks';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as Copy } from '../assets/icons/copy.svg';

const CopyText: React.FC<{ address: string }> = ({ address }) => {
  const [copied, setCopied] = useState(false);

  useUpdateEffect(() => {
    if (!copied) return;

    const clear = setTimeout(() => setCopied(false), 3000);

    return () => {
      clearTimeout(clear);
    };
  }, [copied]);

  if (copied) {
    return <p style={{ lineHeight: '24px' }}>Copied</p>;
  }

  return (
    <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
      <div className="pointer">
        <Copy />
      </div>
    </CopyToClipboard>
  );
};

export default CopyText;
