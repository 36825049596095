import React from "react";
import { getSlicedValue, getTotalValue } from "../helpers/methods";
import useGetReferralsList from "../hooks/useGetReferralsList";
import { IStakeHolder } from "../store/types";
import CopyText from "./CopyText";

interface IReferralsList {
  ref_address: string;
  data: IStakeHolder;
}
const ReferralsList: React.FC<IReferralsList> = ({
  ref_address,
  data: { userRewardDetails, user_address },
}) => {
  const { data, loading, error } = useGetReferralsList(ref_address);

  return (
    <div className="referrals_list-wrapper">
      <div data-position="flex-between">
        <div>
          <p>{getSlicedValue(ref_address)}</p>
          <b>{getTotalValue(userRewardDetails, ref_address)} USDT</b>
        </div>
        <CopyText address={ref_address} />
      </div>
      {loading ? null : error ? null : !data.length ? (
        <div className="referrals_list-wrapper_two">No Referrals yet</div>
      ) : (
        <div className="referrals_list-wrapper_two">
          {data.map((d, index) => (
            <div key={index.toString()} data-position="flex-between">
              <div>
                <p>{getSlicedValue(d)}</p>
                <b>{getTotalValue(userRewardDetails, d)} USDT</b>
              </div>
              <CopyText address={d} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReferralsList;
