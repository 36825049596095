import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.scss';
import { ReactComponent as DashboardIcon } from '../../assets/icons/home.svg';
import { ReactComponent as StakeHolderIcon } from '../../assets/icons/users.svg';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <nav>
        <NavLink
          to={'/'}
          className={({ isActive }) => (isActive ? 'active' : undefined)}
        >
          <DashboardIcon />
          <span>Dashboard</span>
        </NavLink>
        <NavLink
          to={'/stake-holders'}
          className={({ isActive }) => (isActive ? 'active' : undefined)}
        >
          <StakeHolderIcon />
          <span>Stake holders</span>
        </NavLink>
      </nav>
    </div>
  );
};

export default Sidebar;
