import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ethers } from 'ethers';

let CHAINID = 97;

if (process.env.NODE_ENV === 'production') CHAINID = 56;
if (process.env.NODE_ENV === 'development') CHAINID = 97;

export const Injected = new InjectedConnector({
  supportedChainIds: [97]
});

// const rpc="de7757285d664cb6af8239c7fd98a7cc"

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: 'https://mainnet.infura.io/v3/ec03b8dcd95348149519e0be7ac5098e'
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 12000
});

const switchRequest = (chainId = CHAINID) => {
  const { ethereum } = window as any;
  return ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: ethers.utils.hexlify(chainId) }]
  });
};

export const switchNetwork = async () => {
  const { ethereum } = window as any;
  if (ethereum) {
    try {
      await switchRequest();
    } catch (error) {
      console.log(error);
    }
  }
};
