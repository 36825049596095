import { ethers } from 'ethers';

export const getValidReferralCount = async (
  stakingContract: ethers.Contract,
  address: string,
  level: number
) => {
  try {
    if (level === 1) {
      const res = await stakingContract.validLevel1Count(address);
      return Number(res.toString());
    }
    if (level === 2) {
      const res = await stakingContract.validLevel2Count(address);
      return Number(res.toString());
    }
    return 0;
  } catch (error) {
    console.log(error);
    return 0;
  }
};
