import React, { useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { motion } from "framer-motion";
import { ErrorMessage, Form, Formik, Field } from "formik";

import Backdrop from "./Backdrop";
import "./Modal.scss";
import { modalVaraints } from "../../helpers/animation";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Button from "../Button";
import { TransactionContext } from "../../store/context/TransactionContext";
import { IAddGcsForm, IAddGcsPlan, IGcsPlan } from "../../store/types";
import TextField from "../TextField";
import { existingValidationSchema, validationSchema } from "../../helpers/validationSchema";

interface IAddPlanModal {
  modal: boolean;
  handleClose?: () => void;
  gcsPlans: IGcsPlan[];
  selectedGcsPlan: IGcsPlan | undefined;
  refetch: () => Promise<void>;
}

const initialState: IAddGcsForm = {
  name: "GCS",
  apr: 10,
  day: 0,
  gcexaddress: "",
  directbonus: 2,
  withdrawfee: 2,
  rewardaprbonus: 5,
  level1DirectBonus: 40,
  level2DirectBonus: 60,
  xamount: 1,
  yamount: 1,
  zamount: 1,
  validcount: 10,
};

const AddPlanModal: React.FC<IAddPlanModal> = ({
  modal,
  handleClose,
  gcsPlans,
  selectedGcsPlan,
  refetch,
}) => {
  const { setTransaction } = useContext(TransactionContext);
  const { account, chainId, library } = useWeb3React();

  const handleAddPlan = async (values: IAddGcsForm) => {
    if (!account || !chainId) return;
    const formValues: IAddGcsPlan = {
      ...values,
      plan_id: selectedGcsPlan ? selectedGcsPlan.plan_id : gcsPlans.length,
    };
    try {
      setTransaction({ loading: true, status: "pending" });
      if (!selectedGcsPlan) {
        const { addGcsPlan } = await import("../../utils/contractMethods");
        await addGcsPlan(account, library?.provider, chainId, formValues);
      } else {
        const { updateGcsPlan } = await import("../../utils/contractMethods");
        await updateGcsPlan(account, library?.provider, chainId, formValues);
      }
      refetch();
      if (handleClose) handleClose();
      setTransaction({ loading: true, status: "success" });
    } catch (error) {
      console.log(error);
      setTransaction({ loading: true, status: "error" });
    }
  };

  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <motion.div
        className={"addplan_modal"}
        onClick={(e) => e.stopPropagation()}
        variants={modalVaraints}
        animate="animate"
        initial="initial"
        exit="exit"
      >
        <div className="addplan_modal-content">
          <div className="modal-header">
            <h3>{selectedGcsPlan ? `Edit GCS ${selectedGcsPlan.day}` : "Add Staking Plan"}</h3>
            <div className="close" onClick={() => handleClose && handleClose()}>
              <CloseIcon />
            </div>
          </div>
          <Formik
            initialValues={selectedGcsPlan || initialState}
            onSubmit={handleAddPlan}
            validationSchema={
              selectedGcsPlan ? existingValidationSchema : validationSchema(gcsPlans)
            }
            enableReinitialize
          >
            {() => (
              <Form>
                <div className="content">
                  <TextField name="name" label="Plan Name" placeholder="USDT" type="text" />
                  <div className="form_input">
                    <label htmlFor="day">Days</label>
                    <Field
                      type="number"
                      name="day"
                      placeholder="USDT 120"
                      readOnly={selectedGcsPlan ? true : false}
                    />
                    <ErrorMessage name="day" component={"div"} className="error_input" />
                  </div>
                  <TextField name="apr" label="Apr (%)" placeholder="10%" type="number" />
                  <TextField
                    name="gcexaddress"
                    label="Owner address"
                    placeholder="0x06FfFb3fc1b9dBb33EdD75804b..."
                  />
                  <div className="flex-grid">
                    <TextField
                      name="directbonus"
                      label="Direct Bonus (%)"
                      placeholder="2%"
                      type="number"
                    />
                    <TextField
                      name="withdrawfee"
                      label="Withdraw Fee (%)"
                      placeholder="2%"
                      type="number"
                    />
                  </div>
                  <div className="flex-grid">
                    <TextField
                      name="level1DirectBonus"
                      label="Level 1 Direct Bonus (%)"
                      placeholder="40%"
                      type="number"
                    />
                    <TextField
                      name="level2DirectBonus"
                      label="Level 2 Direct Bonus (%)"
                      placeholder="60%"
                      type="number"
                    />
                  </div>
                  <div className="flex-grid">
                    <TextField name="xamount" label="X amount" placeholder="0" type="number" />
                    <TextField name="yamount" label="Y amount" placeholder="0" type="number" />
                  </div>
                  <div className="flex-grid">
                    <TextField name="zamount" label="Z amount" placeholder="0" type="number" />
                    <TextField
                      name="validcount"
                      label="Valid referrals count"
                      placeholder="0"
                      type="number"
                    />
                  </div>
                  <TextField
                    name="rewardaprbonus"
                    label="Referral APR Bonus (%)"
                    placeholder="5%"
                    type="number"
                  />
                  <Button type="submit">Confirm</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddPlanModal;
