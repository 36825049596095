import React from 'react';
import GcsStats from './GcsStats';

import './Home.scss';

const Home: React.FC = () => {
  return (
    <div className="home p-32">
      <GcsStats />
    </div>
  );
};

export default Home;
