import React, { useState } from "react";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";

import "./Header.scss";

import { UnlockWallet } from "../";
import logo from "../../assets/logo/logo.jpeg";
import { useUpdateEffect } from "../../hooks";
import { switchNetwork } from "../../utils/connector";
import AccountModal from "../Modals/AccountModal";
import { ReactComponent as Account } from "../../assets/icons/account.svg";
import NoWalletModal from "../Modals/NoWalletModal";
import { NoEthereumProviderError } from "@web3-react/injected-connector";

const Header: React.FC = () => {
  const { account, error } = useWeb3React();
  const [accountModal, setAccountModal] = useState(false);
  const [nowalletModal, setNowalletModal] = useState(false);

  useUpdateEffect(() => {
    if (error && error instanceof UnsupportedChainIdError) switchNetwork();
    if (error && error instanceof NoEthereumProviderError) setNowalletModal(true);
  }, [error]);

  return (
    <>
      <header className="header pad">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="block_right">
          {account ? (
            <button className="account-btn" onClick={() => setAccountModal(true)}>
              <Account />
              <span>{`${account.slice(0, 6)}...${account.slice(account.length - 6)}`} </span>
            </button>
          ) : (
            <UnlockWallet />
          )}
        </div>
      </header>
      <AccountModal modal={accountModal} handleClose={() => setAccountModal(false)} />
      <NoWalletModal modal={nowalletModal} handleClose={() => setNowalletModal(false)} />
    </>
  );
};

export default Header;
