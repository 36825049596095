import React, { useState } from "react";

import { ReactComponent as Loader } from "../../assets/icons/transaction_processing.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/lock.svg";
import useGcsPlans from "../../hooks/useGcsPlans";
import { getSlicedValue } from "../../helpers/methods";
import { AnimatePresence } from "framer-motion";
import AddPlanModal from "../../components/Modals/AddPlanModal";
import { IGcsPlan } from "../../store/types";

const GcsStats = () => {
  const { isLoading, error, data, refetch } = useGcsPlans();
  const [addModal, setAddModal] = useState(false);
  const [selectedGcsPlan, setSelectedGcsPlan] = useState<IGcsPlan>();

  const handleClose = () => {
    setAddModal(false);
    setSelectedGcsPlan(undefined);
  };

  if (isLoading)
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  if (error)
    return (
      <div className="loader">
        <p>something went wrong</p>
      </div>
    );

  return (
    <>
      <div className="gcsplans_container">
        <h1>Plans</h1>
        <div className="flex-end">
          <button className="btn" onClick={() => setAddModal(true)}>
            <AddIcon />
            <span>Add Plan</span>
          </button>
        </div>
        <div className="gcsplans_card_wrapper">
          {data.map((plan) => {
            return (
              <div key={plan.plan_id} className="gcsplan-card">
                <div data-position="flex-between">
                  <h3>{plan.name}</h3>
                  <button
                    className="btn"
                    onClick={() => {
                      setSelectedGcsPlan(plan);
                      setAddModal(true);
                    }}
                  >
                    <span>Edit</span>
                    <EditIcon />
                  </button>
                </div>
                <div className="gcsplan-card-content mt-20">
                  <div data-position="flex-between">
                    <p>Owner</p>
                    <b>{getSlicedValue(plan.gcexaddress)}</b>
                  </div>
                  <div data-position="flex-between">
                    <p>APR</p>
                    <b>{plan.apr}%</b>
                  </div>
                  <div data-position="flex-between">
                    <p>Total staked</p>
                    <b>{plan.total_staked} USDT</b>
                  </div>
                  <div data-position="flex-between">
                    <p>Direct bonus</p>
                    <b>{plan.directbonus}%</b>
                  </div>
                  <div data-position="flex-between">
                    <p>Reward APR bonus</p>
                    <b>{plan.rewardaprbonus}%</b>
                  </div>
                  <div data-position="flex-between">
                    <p>Level 1 Bonus</p>
                    <b>{plan.level1DirectBonus}%</b>
                  </div>
                  <div data-position="flex-between">
                    <p>Level 2 bonus</p>
                    <b>{plan.level2DirectBonus}%</b>
                  </div>
                  <div data-position="flex-between">
                    <p>Withdraw Fee</p>
                    <b>{plan.withdrawfee}%</b>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <AnimatePresence exitBeforeEnter>
        {addModal && (
          <AddPlanModal
            modal={addModal}
            handleClose={handleClose}
            gcsPlans={data}
            selectedGcsPlan={selectedGcsPlan}
            refetch={refetch}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default GcsStats;
