import * as Yup from 'yup';

export const validationSchema = (gcsPlans) => {
  return Yup.object({
    name: Yup.string().required('This field is required'),
    apr: Yup.number().required('This field is required'),
    day: Yup.number()
      .moreThan(0, 'Invalid Days')
      .test('plan', 'This Plan is already exist', (day) => {
        const d = day ?? 0;
        const isTrue = gcsPlans.find((f) => f.day === d);
        return isTrue ? false : true;
      })
      .required('This field is required'),
    directbonus: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    withdrawfee: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    depositbonus: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    level1DirectBonus: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    level2DirectBonus: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    xamount: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    yamount: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    zamount: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    validcount: Yup.number()
      .positive("This field can't be negative")
      .integer('Invalid format'),
    gcexaddress: Yup.string()
      .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid ethereum address')
      .required('This field is required')
  });
};

export const existingValidationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  apr: Yup.number().required('This field is required'),
  day: Yup.number()
    .moreThan(0, 'Invalid Days')
    .required('This field is required'),
  directbonus: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  withdrawfee: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  depositbonus: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  level1DirectBonus: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  level2DirectBonus: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  xamount: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  yamount: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  zamount: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  validcount: Yup.number()
    .positive("This field can't be negative")
    .integer('Invalid format'),
  gcexaddress: Yup.string()
    .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid ethereum address')
    .required('This field is required')
});
