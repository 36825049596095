import React, { useState } from 'react';

import './StakeHolders.scss';
// import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import ReferralsModal from '../../components/Modals/ReferralModal';
import useStakeHolders from '../../hooks/useStakeHolders';
import { ReactComponent as Loader } from '../../assets/icons/transaction_processing.svg';
import { getSlicedValue } from '../../helpers/methods';
import { BonusType, IStakeHolder } from '../../store/types';
import { AnimatePresence } from 'framer-motion';

const StakeHolders: React.FC = () => {
  const [modal, setModal] = useState<IStakeHolder | null>(null);
  const { isLoading, error, data } = useStakeHolders();

  if (isLoading)
    return (
      <div className="loader">
        <Loader />
      </div>
    );

  if (error)
    return (
      <div className="loader">
        <p>something went wrong</p>
      </div>
    );

  return (
    <>
      <div className="stake-holders p-32">
        <div className="table_container">
          <table>
            <thead>
              <tr>
                <th>Wallet Address</th>
                <th>Level</th>
                <th>Valid Referrals</th>
                <th>Direct Bonus</th>
                <th>Referrals Bonus</th>
                <th>Total Staked</th>
              </tr>
            </thead>
            <tbody>
              {!data.length ? (
                <tr>
                  <td>No Users Found</td>
                </tr>
              ) : (
                data.map((user, i) => {
                  return (
                    <tr key={i.toString()} onClick={() => setModal(user)}>
                      <td>
                        <input
                          type={'checkbox'}
                          checked={user.validReferralsCount >= 2}
                          readOnly
                        />
                        <span>{getSlicedValue(user.user_address)}</span>
                      </td>
                      <td>{user.level}</td>
                      <td>{user.validReferralsCount}</td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 6
                        }).format(
                          user.userRewardDetails
                            .filter((f) => f.type === BonusType.DIRECT)
                            .reduce((a, b) => a + b.earnedBonus, 0)
                        )}{' '}
                        GCS
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 6
                        }).format(
                          user.userRewardDetails
                            .filter((f) => f.type === BonusType.REFERRAL_APR)
                            .reduce((a, b) => a + b.earnedBonus, 0)
                        )}{' '}
                        GCS
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 4
                        }).format(user.total_staked)}{' '}
                        GCS
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
      <AnimatePresence>
        {modal && (
          <ReferralsModal
            modal={modal ? true : false}
            handleClose={() => setModal(null)}
            data={modal}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default StakeHolders;
