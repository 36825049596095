import React, { useState } from "react";
import { motion } from "framer-motion";

import "./Modal.scss";
import Backdrop from "./Backdrop";
import { modalVaraints } from "../../helpers/animation";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as DatabaseIcon } from "../../assets/icons/database.svg";
import { ReactComponent as UpArrow } from "../../assets/icons/up_arrow.svg";
import { ReactComponent as NoReferrals } from "../../assets/icons/no_referrals.svg";
import { IStakeHolder } from "../../store/types";
import { getSlicedValue, getTotalValue } from "../../helpers/methods";
import ReferralsList from "../ReferralsList";
import CopyText from "../CopyText";

interface IReferralsModal {
  modal: boolean;
  handleClose?: () => void;
  data: IStakeHolder;
}

const ReferralsModal: React.FC<IReferralsModal> = ({ modal, handleClose, data }) => {
  const [open, setOpen] = useState(false);
  const [isLive, setIsLive] = useState(true);

  const renderReferrals = (
    <div className="referrals_dropdown">
      <div
        data-position="flex-between"
        className={open ? "referrals_dropdown-header active" : "referrals_dropdown-header"}
        onClick={() => setOpen((o) => !o)}
      >
        <p className="text-primary">Referrals</p>
        <UpArrow />
      </div>
      {open && (
        <>
          {!data.referred_users.length ? (
            <div className="no_referrals">
              <NoReferrals />
              <p>No referrals</p>
            </div>
          ) : (
            <>
              {data.level === 2 ? (
                <div className="referrals_dropdown-content">
                  {data.referred_users.map((ref, i) => {
                    return (
                      <div data-position="flex-between">
                        <div>
                          <p>{getSlicedValue(ref)}</p>
                          <b>{getTotalValue(data.userRewardDetails, ref)} USDT</b>
                        </div>
                        <CopyText address={ref} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="referrals_dropdown-content">
                  {data.referred_users.map((ref, i) => {
                    return <ReferralsList key={i.toString()} ref_address={ref} data={data} />;
                  })}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );

  const renderStakedDetails = (
    <div className="staked_details-wrapper">
      {!data.ongoingStakingDetails.length ? (
        <section>
          <p style={{ textAlign: "center" }}>No Histories Found</p>
        </section>
      ) : (
        data.ongoingStakingDetails.map((details, index) => (
          <div key={index.toString()}>
            <p>{index + 1}.</p>
            <div>
              <p>Staked</p>
              <b>
                {new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 0,
                }).format(details.staked_amount)}{" "}
                USDT
              </b>
            </div>
            <div>
              <p>Plan</p>
              <b>{details.days} days</b>
            </div>
            <div>
              <p>Earning reward</p>
              <b>
                {new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 0,
                }).format(details.totalRewards)}
              </b>
            </div>
          </div>
        ))
      )}
    </div>
  );

  const renderFinishedStakedDetails = (
    <div className="staked_details-wrapper">
      {!data.finishedStakingDetails.length ? (
        <section>
          <p style={{ textAlign: "center" }}>No Histories Found</p>
        </section>
      ) : (
        data.finishedStakingDetails.map((details, index) => (
          <div key={index.toString()}>
            <p>{index + 1}.</p>
            <div>
              <p>Staked</p>
              <b>{details.staked_amount} USDT</b>
            </div>
            <div>
              <p>Plan</p>
              <b>{details.days} days</b>
            </div>
            <div>
              <p>Earned reward</p>
              <b>
                {new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 0,
                }).format(details.totalRewards)}
              </b>
            </div>
          </div>
        ))
      )}
    </div>
  );

  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <motion.div
        className={"referrals_modal"}
        onClick={(e) => e.stopPropagation()}
        variants={modalVaraints}
        animate="animate"
        initial="initial"
        exit="exit"
      >
        <div className="referrals_modal-content">
          <div className="modal-header">
            <h3>Account</h3>
            <div className="close" onClick={() => handleClose && handleClose()}>
              <CloseIcon />
            </div>
          </div>
          {!open && (
            <div className="content">
              <div data-position="flex" className="mb-20">
                <ProfileIcon />
                <h3>{getSlicedValue(data.user_address)}</h3>
              </div>
              <div className="mb-20">
                <h3>LVL {data.level}</h3>
              </div>
              <div data-position="flex-between" className="mb-15">
                <p>Total Staked</p>
                <div data-position="flex">
                  <DatabaseIcon />
                  <p className="text-primary">
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                    }).format(data.total_staked)}{" "}
                    USDT
                  </p>
                </div>
              </div>
              <div data-position="flex-between" className="mb-15">
                <p>Current Staked</p>
                <p className="text-primary">
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                  }).format(data.current_stake)}{" "}
                  USDT
                </p>
              </div>
              <div data-position="flex-between" className="mb-15">
                <p>Withdrawn</p>
                <p className="text-primary">
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                  }).format(data.withdrawn)}{" "}
                  USDT
                </p>
              </div>
              <div className="staked_details">
                <div className="staked_details-header" data-position="flex-between">
                  <h3>Details</h3>
                  <div>
                    <button
                      onClick={() => setIsLive(true)}
                      className={isLive ? "active" : undefined}
                    >
                      Live
                    </button>
                    <button
                      onClick={() => setIsLive(false)}
                      className={!isLive ? "active" : undefined}
                    >
                      Finished
                    </button>
                  </div>
                </div>
                {isLive ? renderStakedDetails : renderFinishedStakedDetails}
              </div>
            </div>
          )}
          {data.level !== 3 && renderReferrals}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ReferralsModal;
