export type IGcsPlan = {
  name: string;
  apr: number;
  stakeaddress: string[];
  day: number;
  directbonus: number;
  gcexaddress: string;
  total_staked: number;
  withdrawfee: number;
  plan_id: number;
  rewardaprbonus: number;
  level1DirectBonus: number;
  level2DirectBonus: number;
  xamount: number;
  yamount: number;
  zamount: number;
  validcount: number;
};

export type IAddGcsForm = {
  name: string;
  apr: number;
  day: number;
  gcexaddress: string;
  directbonus: number;
  withdrawfee: number;
  rewardaprbonus: number;
  level1DirectBonus: number;
  level2DirectBonus: number;
  xamount: number;
  yamount: number;
  zamount: number;
  validcount: number;
};

export type IAddGcsPlan = {
  name: string;
  plan_id: number;
  apr: number;
  day: number;
  gcexaddress: string;
  directbonus: number;
  withdrawfee: number;
  rewardaprbonus: number;
  level1DirectBonus: number;
  level2DirectBonus: number;
  xamount: number;
  yamount: number;
  zamount: number;
  validcount: number;
};

export interface IUserStakedDetails {
  staked_amount: number;
  apr: number;
  start_time: number;
  end_time: number;
  days: number;
  plan: number;
  old_user: boolean;
  status: string;
  index: number;
  totalRewardsWithInitialAmount: number;
  totalRewards: number;
  dailyReward: number;
}

export interface IUserRewardDetails {
  address: string;
  earnedBonus: number;
  type: BonusType;
}

export interface IStakeHolder {
  ongoingStakingDetails: IUserStakedDetails[];
  finishedStakingDetails: IUserStakedDetails[];
  level: number;
  referrer: string;
  referral_id: number;
  referrer_endtime: number;
  referred_users: string[];
  validReferralsCount: number;
  user_address: string;
  total_staked: number;
  current_stake: number;
  withdrawn: number;
  totalRewards: number;
  userRewardDetails: IUserRewardDetails[];
}

export interface IUserReferrals {
  referee_address: string;
  earned: number;
}

export enum BonusType {
  DIRECT = 'DIRECT',
  REFERRAL_APR = 'REFERRAL_APR'
}
